.Main-secondsection {
    background-color: #e2e0e0;
    text-align: center;
    padding-bottom: 50px;
  }
  
  /*hive*/
  
  .Hive {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
    .Hive img {
    max-height: 200px;
  }

/*hive one*/  

    .HiveOne {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

    .Hive .HiveOne img {
    padding: 0 113px;
  }  
    .HiveOne .HiveTwoTitle {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    position: relative;
  }
    .HiveOne .HiveOneTitle{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    position: relative;
  }
    .HiveOne .HiveOneTitle h3{
    max-width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black; 
    text-align: center;
 }  
  
  .HiveOne .HiveTwoTitle h3 {
    max-width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black; 
    text-align: center;
  }

/*hive two*/  

  .Hive .HiveTwo img {
    margin-top: -80px;
  }
  .HiveTwo .HiveOneTitle{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    position: relative;
  }
  .HiveTwo .HiveOneTitle h3{
    max-width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black; 
    text-align: center;
    margin-top: -40px;
 }  
/*hive three*/  
  .HiveThree {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
  
  .Hive .HiveThree img {
    padding: 0 113px;
    margin-top: -80px;
  }

  .HiveThree .HiveTwoTitle {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    position: relative;
  }
    .HiveThree .HiveOneTitle{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    position: relative;
  }
    .HiveThree .HiveOneTitle h3{
    max-width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black; 
    text-align: center;
    margin-top: -40px;
 }  
  
  .HiveThree .HiveTwoTitle h3 {
    max-width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black; 
    text-align: center;
    margin-top: -40px;
 }
  
/*Title with background*/
.Title-background {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  padding-top: 50px;
  color: black;
  text-align: center;
  background-image: url('../../img/diversos/titulo.png');
  background-repeat: no-repeat;
  background-position: center 93%;
  background-size: contain;
  height: 95px;
}


@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .Title-background{
      min-width: 340px;
      background-position: center 93%;
      height: 93px;
    }
}
  
  @media only screen and (min-width: 600px) and (max-width: 820px) {
    .Main-secondsection {
       padding-bottom: 30px;
    }
    .Title-background {
      padding-top: 30px;
      background-position: center 93%;
      height: 58px;
    }
    /*hive*/
    .Hive{
        margin-top: 30px;
    }
    .Hive img {
        max-height: 150px;
      }

    /*hive one*/  

    .Hive .HiveOne img {
        padding: 0 84.34px;
      }  
        .HiveOne .HiveOneTitle h3{
        max-width: 110px;
        font-size: 15px;
     }  
      
      .HiveOne .HiveTwoTitle h3 {
        max-width: 110px;
        font-size: 15px;
      }
    /*hive two*/  

    .Hive .HiveTwo img {
        margin-top: -60px;
    }

    .HiveTwo .HiveOneTitle h3{
        max-width: 110px;
        font-size: 15px;
        margin-top: -27px;
    }
    /*hive three*/  
  
    .Hive .HiveThree img {
        padding: 0 84.34px;
        margin-top: -60px;
    }

    .HiveThree .HiveOneTitle h3{
        max-width: 110px;
        font-size: 15px;
        margin-top: -27px;
    }  
  
    .HiveThree .HiveTwoTitle h3 {
        max-width: 110px;
        font-size: 15px;
        margin-top: -27px;
    }

  }
  
  @media only screen and (max-width: 600px) {
    .Main-secondsection {
      padding-bottom: 25px;
    }
    .Title-background {
      padding-top: 25px;
      background-position: center 93%;
      height: 47px;
    }
    /*hive*/
    .Hive{
        margin-top: 25px;
    }
    .Hive img {
        max-height: 100px;
      }

    /*hive one*/  

    .Hive .HiveOne img {
        padding: 0 55.27px;
      }  
        .HiveOne .HiveOneTitle h3{
        max-width: 90px;
        font-size: 10px;
     }  
      
      .HiveOne .HiveTwoTitle h3 {
        max-width: 90px;
        font-size: 10px;
      }
    /*hive two*/  

    .Hive .HiveTwo img {
        margin-top: -35px;
    }

    .HiveTwo .HiveOneTitle h3{
        max-width: 90px;
        font-size: 10px;
        margin-top: -20px;
    }
    /*hive three*/  
  
    .Hive .HiveThree img {
        padding: 0 55.27px;
        margin-top: -55px;
    }

    .HiveThree .HiveOneTitle h3{
        max-width: 90px;
        font-size: 10px;
        margin-top: -27px;
    }  
  
    .HiveThree .HiveTwoTitle h3 {
        max-width: 90px;
        font-size: 10px;
        margin-top: -27px;
    }
  }
  