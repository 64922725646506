.Main-about{
    display: flex;
    margin-top: 30px;
}

.Left-about{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -70px;
}

.Left-about img{
  height: 400px;
  margin-top: 50px;
}

.Left-about h3{
  margin-top: 20px;
  padding: 0 60px 10px 60px;
  border-bottom: 2px solid #FECF07;
  margin-bottom: 50px;
  font-weight: lighter;
}

.Right-about{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.Right-about p{
  max-width: 600px;
  font-weight: lighter;
}

.Mobile-show{
  display: none;
}

/*Title with background*/
.Title-about {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    padding-top: 50px;
    color: black;
    text-align: center;
    background-image: url("../../img/diversos/tituo\ menor.png");
    background-repeat: no-repeat;
    background-position: center 90%;
    background-size: contain;
    height: 95px;
    min-width: 350px;
    max-width: 400px;
  }
  

  @media only screen and (min-width: 820px) and (max-width: 1024px) {
    .Title-about {
      max-width: 350px;
      min-width: 300px;
      background-position: center 25%;
      padding-top: 0;
    }
    .Left-about img{
      height: 300px;
      margin-top: -10px;
    }

    .Right-about p{
      max-width: 400px;
      margin: 30px;
    }

  }
  
  @media only screen and (min-width: 600px) and (max-width: 820px) {
    .Title-about {
      max-width: 250px;
      min-width: 200px;
      background-position: center 14%;
      padding-top: 0;
    }
    .Left-about img{
      height: 200px;
    }
    .Left-about h3{
      padding: 0 40px 10px 40px;
      max-width: 300px;
      text-align: center;
    }
    .Right-about p{
      max-width: 300px;
      margin: 30px;
    }

  }
  
  @media only screen and (max-width: 600px) {
    .Title-about {
      min-width: 200px;
      padding-top: 0;
      background-position: center 10%;
    }

    .Right-about{
      display: none;
    }

    .Left-about{
      width: 100%;
    }

    .Left-about img{
      margin-top: -30px;
      height: 250px;
    }

    .Left-about h3{
      padding: 0 40px 10px 40px;
      max-width: 300px;
      text-align: center;
      font-size: 15px;
    }

    .Mobile-show{
      display: flex;
      max-width: 300px;
      font-size: 13px;
      margin-bottom: 25px;
      font-weight: lighter;
      margin-top: -10px;
    }
  }
  