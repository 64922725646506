.Main-banner{
    background-color: #353430;
    text-align: center;
}

.Main-banner h2{
    color: #fff;
    font-weight: lighter;
    padding: 50px;
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
    .Main-banner h2{
        font-size: 30px;
    }
  }
  
  @media only screen and (min-width: 600px) and (max-width: 820px) {
    .Main-banner h2{
        padding: 30px;
    }

  }
  
  @media only screen and (max-width: 600px) {

      .Main-banner h2{
        padding: 25px;
      }

   
  }