.Main-hero {
    width: 100%;
    display: flex;
    align-items: center;
    height: 500px;
    margin-top: 20px;
    margin-top: 0;
  }
  
  .Left {
    width: 50%;
    text-align: center;
    padding-left: 10%;
  }
  
  .Thin {
    font-weight: lighter;
  }
  
  h1 {
    margin-bottom: 10px;
  }
  
  h2 {
    margin-top: 0;
    font-weight: lighter;
  }
  
  .Left button {
    background-color: #FECF07;
    color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 15px;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .Left button:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
  }
  
  .Right {
    width: 50%;
    text-align: center;
    padding-right: 10%;
  }
  
  .Right img {
    max-height: 400px;
  }
  
  @media only screen and (min-width: 820px) and (max-width: 1024px) {
    .Left {
      padding-left: 5%;
      margin-right: 5%;
    }
    .Right img {
      max-height: 350px;
    }
  }
  
  @media only screen and (min-width: 600px) and (max-width: 820px) {
    .Main-hero {
      height: 350px;

    }
    .Left {
      padding-left: 5%;
      margin-right: 5%;
    }
    .Right img {
      max-height: 250px;
    }
    .Left button {
      font-size: 15px;
    }
    
  }
  
  @media only screen and (max-width: 600px) {
    .Main-hero {
      height: 250px !important;
    }
    .Left {
      padding-left: 5%;
      margin-right: 5%;
    }
    .Main-hero {
      height: 300px;
    }
    .Right img {
      max-height: 150px;
      margin-right: 25px;
    }

    .Left button {
      font-size: 15px;
      padding: 12px;

    }
  }
  