* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  color: #FECF07;
  font-size: 50px;
}

h2 {
  font-size: 35px;
}

button {
  all: unset;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.App {
  width: 100%;
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  h3 {
    font-size: 23px;
    font-weight: lighter;
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 15px;
}
}