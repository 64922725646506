.Header {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column; 
}

.Main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.Main-header img {
  max-width: 150px;
}

.Menu {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.Menu button {
  font-size: 15px;
}

.Menu button:hover {
  color: #FECF07;
}

.MenuMobile {
  display: none;
}

.Menuscreen {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  background-color: #FECF07;
  padding: 10px;
  padding-right: 50px;
}

.Menuscreen button {
  margin-top: 10px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Menu {
    width: 250px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .Menu button {
    font-size: 15px;
  }
  .Menu {
    width: 220px;
  }
  .Main-header img {
    max-width: 140px;
  }
}

@media only screen and (max-width: 480px) {
  .Menu {
    display: none;
  }
  .Main-header img {
    max-width: 120px;
    margin-right: 30px;
  }
  .MenuMobile {
    display: inline;
    margin-top: 15px;
    margin-right: -10px;
  }
  .MenuMobile img {
    max-height: 20px;
  }

}

#about, #services {
  margin-top: 0; /* Ajuste conforme necessário */
}