.Main-Services {
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}

.Left-services {
  width: 50%;
  text-align: center;
  padding: 20px;
  margin-right: 50px;
  margin-top: 100px;
}

.Right-service {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;  
  align-items: center;
}

.Container {
  background-color: #e2e0e0;
  border-radius: 15px;
  min-width: 450px;
  max-width: 450px;
  padding: 20px;
  margin: 35px 20px 0 20px;
  display: flex;
  flex-direction: column;
}

.Header-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Header-info h4 {
  margin: 0;
}

.Container img {
  height: 20px;
}

/*Title with background*/
.Title-serv {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  padding-top: 50px;
  color: black;
  text-align: center;
  background-image: url("../../img/diversos/tituo\ menor.png");
  background-repeat: no-repeat;
  background-position: center 90%;
  background-size: contain;
  height: 95px;
  min-width: 350px;
  max-width: 400px;
}

.Container h4 {
  font-size: 15px;
  font-weight: lighter;
}

.Content {
  display: none; 
}

.Content.show {
  display: block; /* Mostra quando expandido */
}

.More-info{
  margin-top: 20px;
}

.More-info p{
  font-weight: lighter;
  font-size: 15px;
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .Left-services img {
    max-width: 350px;
  }
  .Main-Services {
    padding: 30px;
  }
  .Title-serv {
    max-width: 350px;
    min-width: 300px;
    background-position: center 25%;
    padding-top: 0;
  }
  .Container {
    min-width: 380px;
    max-width: 450px;
    padding: 20px;
    margin-top: -16px;
    margin-bottom: 30px;
  }
  .More-info p{
    font-size: 15px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  .Left-services img {
    max-width: 200px;
  }
  .Main-Services {
    padding: 30px;
    padding-top: 25px;
  }
  .Title-serv {
    max-width: 250px;
    min-width: 200px;
    background-position: center 14%;
    padding-top: 0;
  }
  .Container {
    min-width: 290px;
    max-width: 350px;
    padding: 15px;
    margin-top: -42px;
    margin-bottom: 55px;
  }

  .More-info p{
    font-size: 15px;
  }


}

@media only screen and (max-width: 600px) {
  .Left-services {
    display: none;
  }
  .Main-Services {
    padding: 0px;
  }
  .Title-serv {
    min-width: 200px;
    padding-top: 0;
    background-position: center 10%;
  }

  .Container {
    min-width: 320px;
    max-width: 350px;
    padding: 15px;
    margin-top: -42px;
    margin-bottom: 55px;
  }

  .Container h4 {
    font-size: 15px;
  }
  .More-info{
    font-size: 14px;
  }
  .Header-info h4 {
    font-size: 14px;
  }

  .More-info p{
    font-size: 13px;
    font-weight: lighter;
  }
}
