.Main-Footer{
    background-color: #FECF07;
    width: 100%;
    padding: 50px;
}

.Main-Footer p{
    color: #fff;
    font-size: 14px;
    text-align: center;
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
    .Main-Footer{
        padding: 30px;
    }

  }
  
@media only screen and (min-width: 600px) and (max-width: 820px) {
    .Main-Footer{
        padding: 25px;
    }
  }
  
@media only screen and (max-width: 600px) {
    .Main-Footer{
        padding: 25px;
    }

  }
  